
// Accepter une commande
import {userStore} from "@/store/pinia-stores/user.store";
import api from "@/services-apis/axios-init";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import {payementStore} from "@/store/pinia-stores/payements-store";

/*
* Confirmer un / plusieurs paiements
* */
export const confirmPayement = (data, refetch, messageSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = payementStore()
    store.setIsLoading(true)

    api.post(`/admin/payement/confirm`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || 'Payement confirmé avec succès', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de confirmer un paiement actuellement', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

// Désactiver les notifications d'un / plusieurs paiements
export const editNotificationState = (data, refetch, messageSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = payementStore()
    store.setIsLoading(true)

    api.post(`/admin/payement/switch-notification-state`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || 'Notifications désactivées', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de désactiver les notifications d\'un paiement actuellement', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

