// Changer le price de l'article
import {userStore} from "@/store/pinia-stores/user.store";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import api from "@/services-apis/axios-init";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";

export const setArticlePrice = (idArti, data, callback, errorSave) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = commandeStore()

    store.setIsLoading(true)

    api.post(`/admin/article/${idArti}/set-price`, data, {headers}).then(res => {
        callback && callback()
        console.log(res.data.mesage)

        // Arrêter le chargement
        store.setIsLoading(false)

    }).catch(err => {
        callback && callback()
        // Arrêter le chargement
        store.setIsLoading(false)


        let msg = err.response.data.message

        toast.error(msg || 'impossible de changer le price', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            onClose: () => errorSave && errorSave()
        })

        console.log({err})

    })
}
