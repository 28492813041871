<script setup>
import FormButton from "@/components/FormButton.vue";
import FormInput from "@/components/FormInput.vue";
import {setApprobationCommande} from "@/services-apis/commandes";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import {ref} from "vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import {userStore} from "@/store/pinia-stores/user.store";

const {refetch, commande} = useCommande()

const store = commandeStore()
const ustore = userStore()
const price = ref("")

const closer = ref(null)

/*Lancer la recherche*/
const handleSetApprobation = () => {
  if (Number(price.value) < 10) {
    // Message du succès d'enregistrement user / admin
    return toast.error('Donnez un price valide', {
      position: 'bottom-center',
      hideProgressBar: true,
      bodyClassName: 'body-toaster',
      toastStyle: Styles.toastStyle,
      autoClose: 2000,
      // onClose: () => router.push('/')
    })
  }

  setApprobationCommande({
    approbation: true,
    price: price.value,
    adminId: ustore.user?.id
  }, refetch, '', () => {
  })
}

</script>

<!--Le composant qui lance les démarches -->
<template>
  <!-- Modal -->
  <div class="modal fade" id="takeStepCommande" tabindex="-1" aria-labelledby="takeStepCommandeLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <div class="modal-header">
          <div class="col">
            <h5 class="modal-title" id="exampleModalLabel">Lancer la démarche</h5>
            <span class="text-xs text-bold text-dark">{{ commande?.title }}</span>
          </div>
          <!--          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
        </div>

        <div class="modal-body">

          <p class="text-dark font-weight-normal text-xs">
            En lançant la demarche, vous signalez à l'
            auteur que cette commande répond à nos critères et que ses
            articles demandés ont bien été enregistrés !
          </p>

          <strong class="text-bold text-sm text-dark">Frais de service</strong>
          <form-input type="number" v-model="price" placeholder="Prix total des frais de service (min: 10$)"
                      class="w-75"/>

          <p class="text-dark text-bold text-sm">
            Ce que l'auteur<span class="text-gradient text-dark font-weight-normal">(client)</span> verra:</p>
          <ol>
            <li class="text-dark text-xs mb-1">En attente de paiement des frais de service</li>
            <li class="text-dark text-xs mb-1">Commande acceptée</li>
            <li class="text-dark text-xs mb-1">Commande disponible</li>
          </ol>

          <div class="modal-footer">
            <div class="flex align-items-end">
              <form-button ref="closer" class="text-xs btn-sm border-radius-xl font-weight-normal bg-danger m-1"
                           type="button"
                           data-bs-dismiss="modal"
                           aria-label="Close">
                Annuler
              </form-button>
              <form-button class="text-xs btn-sm border-radius-xl font-weight-normal m-1" type="button"
                           @click="handleSetApprobation">
                <div v-if="store.isLoading">Go</div>
                Lancer maintenant
              </form-button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>
