// Donner d'approbation d'une commande
// interface ApprobationReq {
//     approbation: boolean,
//         price?: string,
//         adminId?: number | null
// }

// Accepter une commande en lançant sa recherche et en fixant le price de la recherche
import {userStore} from "@/store/pinia-stores/user.store";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import api from "@/services-apis/axios-init";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";

// Accepter une commande
export const setApprobationCommande = (data, refetch, messageSuccess, closeModal) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = commandeStore()

    store.setIsLoading(true)
    const idC = store?.currentId

    api.patch(`/admin/command/${idC}/setApprobation`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // On change l'état de la commande actuelle
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // On ferme le modal
        closeModal()

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || 'Demarches lancées', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de lancer cette recherche pour l\'instant', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}


// Révoquer une commande
export const cancelApprobationCommande = (data, refetch, msgSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = commandeStore()

    store.setIsLoading(true)
    const idC = store?.currentId

    api.patch(`/admin/command/${idC}/cancelApprobation`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // Arrêter le chargement
        store.setIsLoading(false)

        // Recharger la commande
        refetch()

        // Message du succès d'enregistrement user / admin
        toast.success(msgSuccess || msg || 'Recherche interrompue', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
            // onClose: () => router.push('/')
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible d\'intérrompred cette recherche pour l\'instant', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}


// Signaler qu'une commande est disponible maintenant
export const setCommandeIsAvailableNow = (data, refetch, msgSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = commandeStore()

    store.setIsLoading(true)
    const id = store?.currentId

    api.patch(`/admin/command/${id}/setAvailable`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // Arrêter le chargement
        store.setIsLoading(false)

        // Recharger la commande
        refetch()

        // Message
        toast.success(msgSuccess || msg || 'Disponible maintenant', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de rendre disponible cette commande', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}
