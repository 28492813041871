<script setup>
</script>

<template>
  <div class="d-flex justify-content-center p-3">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Chargement...</span>
      <div class="spinner-grow spinner-grow-sm" role="status">
        <span class="visually-hidden">Chargement...</span>
      </div>
    </div>
  </div>
</template>
