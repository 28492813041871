<script setup>
import FacturationsTable from "@/views/components/FacturationsTable.vue";
import Loading from "@/views/components/Loading.vue";
import {usePayements} from "@/services-apis/payements/hooks.payements";
const {isLoading} = usePayements()
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <facturations-table />
        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>
