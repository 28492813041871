import {useQuery} from "@tanstack/vue-query";
import api from "@/services-apis/axios-init";
import {userStore} from "@/store/pinia-stores/user.store";

export const useUser = () => {
    let token = userStore().tokens?.accessToken

    let headers = {'Authorization': `Bearer ${token}`};

    // Rappelle de l'utilisateur
    async function getInfos() {
        const res = await api.get('/admin/get-info', {headers})
        return res.data.data
    }

    const {data, isLoading, refetch} = useQuery({queryKey: ['get-infos-admin'], queryFn: getInfos, refetchInterval: 3000});

    return {
        user: data, isLoading, refetch
    }
}
