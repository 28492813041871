import {defineStore} from "pinia";
import router from "@/router";

const localUser = localStorage.getItem("user");
const userData = localUser ? JSON.parse(localUser) : localUser;

const localTokens = localStorage.getItem("tokens");
const tokensData = localTokens ? JSON.parse(localTokens) : localTokens;

const isRememberSession = JSON.parse(localStorage.getItem("remember-me" || 'false'))

export const userStore = defineStore('userStoreId', {

    state: () => {
        return {
            isLoading: false,
            user: userData,
            rememberMe: isRememberSession,
            tokens: tokensData,
        }
    },

    actions: {
        // Sauvegarder les données de l'utilisateur en local et dans le store en temps réel
        setUserInfos(user, tokens, remember = false) {
            this.user = user
            this.tokens = tokens
            this.rememberMe = remember
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('tokens', JSON.stringify(tokens))
            localStorage.setItem('remember-me', JSON.stringify(remember))
        },

        // Sauvegarder les données de l'utilisateur en local et dans le store en temps réel
        disconnect() {
            this.user = ''
            this.tokens = ''
            this.rememberMe = ''
            localStorage.clear()
            router.push('/signin')
        },

        // Chargement utilisateur
        setIsLoading(isLoad) {
            this.isLoading = isLoad
        }
    }
})
