import {defineStore} from "pinia";

const curr = sessionStorage.getItem('cp')
const CURRENT = curr ? JSON.parse(curr) : curr

const currID = sessionStorage.getItem('cpId')
const CURRENT_ID = currID ? JSON.parse(currID) : currID

export const payementStore = defineStore('payementStoreId', {

    state: () => {
        return {
            textSearch: "",
            page: 0,
            items: 5,
            current: CURRENT,
            currentId: CURRENT_ID,
            isLoading: false,
        }
    },

    actions: {

        // Changer le paiement courant
        setCurrent(payement) {
            this.current = payement
            sessionStorage.setItem('cp', JSON.stringify(payement))
        },

        // Changer l'id sur le paiement courant
        setCurrentId(id) {
            this.currentId = id
            sessionStorage.setItem('cpId', JSON.stringify(id))
        },

        // Chargement
        setIsLoading(loading) {
            this.isLoading = loading
        },

        /*
                // Changer le texte de la recherche
                setTextSearch(value: string) {
                    this.textSearch = value
                },*/

        // Changer les valeurs de la pagination
        setPagination(page, items) {
            this.page = page
            this.items = items
        },

    }
})
