<script setup>
import ArgonButton from "@/components/FormButton.vue";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import dayjs from "dayjs";
import ArticleItemBtn from "@/views/components/ArticleItemBtn.vue";
import {payementStore} from "@/store/pinia-stores/payements-store";
import router from "@/router";
import {usePayement} from "@/services-apis/payements/hooks.payements";
import DetailArticle from "@/views/modals/DetailArticle.vue";

const {commande} = useCommande()
const typeText = (pymnt) => {
  return pymnt?.type === "paye_purcharge_fee" ? 'Frais service'
      : pymnt?.type === 'acompte_commande' ? 'Paiement acompte'
          : pymnt?.type === 'buy_article' ? 'Achat article' : pymnt?.type
}

// Aller à un autre payement
const strPym = payementStore()
const {refetch}= usePayement()
const gotoOnePayement = async(pymnt) => {
  strPym.setCurrentId(pymnt?.id)
  await refetch()
  await router.push({name: 'DetailsPayement'})
}

</script>
<template>
  <div class="card h-100 mb-4">
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-md-12">
          <h6 class="mb-0">Transactions & articles</h6>
        </div>
      </div>
    </div>
    <div class="card-body pt-4 p-3">

      <!--      Les dernières transactions de la commande-->
      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        transactions
      </h6>

      <!--      Aucun payement enregistré-->
      <div v-if="commande?.payements.length===0" class="text-center">
        <img src="@/assets/img/paye.jpg" alt="" class="img-fluid" style="height: 100px;width: 100px"/>
        <p class="text-normal text-sm text-dark">Aucun paiement enregistré pour cette commande</p>
      </div>

      <ul class="list-group">
        <li
            @click="gotoOnePayement(payement)"
            v-for="payement in commande?.payements" :key="payement.id"
            class="btn shadow-none text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex align-items-center">
            <argon-button
                color="success"
                variant="outline"
                size="sm"
                class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
            >
              <i class="fas fa-arrow-up" aria-hidden="true"></i>
            </argon-button>

            <div class="d-flex flex-column">
              <h6 class="mb-1 text-dark text-sm">{{ typeText(payement) }}</h6>
              <span class="text-xs text-start">{{ dayjs(payement?.createdAt).format('DD MMM, à HH:mm') }}</span>
            </div>

          </div>
          <div
              class="d-flex align-items-center text-success text-gradient text-lg font-weight-bold"
          >
            + {{ payement?.devise?.symbole }} {{ payement?.totalPayed }}
          </div>
        </li>

      </ul>

      <!--      Les articles de la commandes-->
      <h6 class="text-uppercase text-body text-xs font-weight-bolder my-3">
        articles
      </h6>
      <ul class="list-group">
        <li v-for="article  in commande?.articles" :key="article.id"
            class="btn shadow-card text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <article-item-btn :article="article"/>
        </li>

      </ul>
    </div>

    <DetailArticle/>
  </div>

</template>
