<script setup>
import {onBeforeUnmount, onBeforeMount, ref, computed} from "vue";
import {useStore} from "vuex";
import Navbar from "@/examples/PageLayout/NavbarAuth.vue";
import FormInput from "@/components/FormInput.vue";
import FormSwitch from "@/components/FormSwitch.vue";
import FormButton from "@/components/FormButton.vue";
import {_loginWithAndPassword} from "@/services-apis/users";
import {userStore} from '@/store/pinia-stores/user.store'

const body = document.getElementsByTagName("body")[0];

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});


// Les données du formulaire
const email = ref('')
const password = ref('')
const remember = ref(false)

const userState = userStore()
const showPw = ref(false);
const textBtnShowHide = computed(() => (showPw.value ? "Cacher le mot de passe" : "Afficher le mot de passe"));

/**
 * Se connecter à un compte un administrateur
 * */
const login = () => {
  _loginWithAndPassword(email.value.trim(), password.value.trim(), remember.value)
}

</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
            isBlur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
            v-bind:darkMode="true"
            isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
                class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Se connecter</h4>
                  <p class="mb-0">Entrez votre adresse email et mot de passe</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="login">
                    <div class="mb-3">
                      <form-input
                          v-model="email"
                          id="email"
                          type="email"
                          placeholder="Email"
                          name="email"
                          size="lg"
                      />
                    </div>
                    <div class="mb-3">
                      <form-input
                          v-model="password"
                          id="password"
                          :type="showPw ? 'text': 'password'"
                          placeholder="Mot de passe"
                          name="password"
                          size="lg"
                      />
                    </div>


                    <button type="button" class="btn btn-link text-dark pt-0" @click="showPw = !showPw">
                      {{ textBtnShowHide }}
                    </button>

                    <form-switch :checked="remember" @change="remember=!remember" id="rememberMe" name="remember-me">
                      Se souvenir de moi ?
                    </form-switch>

                    <div class="text-center">
                      <form-button
                          class="mt-4"
                          variant="gradient"
                          color="success"
                          fullWidth
                          size="lg"
                      >
                        <span v-if="userState.isLoading">Connexion cours...</span>
                        <span v-else>Connexion</span>
                      </form-button
                      >
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    N'avez-vous pas de compte ?
                    <router-link to="/signup"
                                 class="text-success text-gradient font-weight-bold"
                    >Créez-en un
                    </router-link
                    >
                  </p>
                </div>
              </div>
            </div>
            <div
                class="top-0 my-auto text-center col-7 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                  class="position-relative h-100 m-4 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                  style="
                  background-image: url('https://img.freepik.com/vecteurs-libre/illustration-concept-authentification-deux-facteurs_114360-5280.jpg?t=st=1719298587~exp=1719302187~hmac=9d736be9424e3da06cfd9fca62043c98756fd84bbe3ebc20ab4c6acd50b8d34e&w=740');
                  background-size: cover;
                "
              >

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
