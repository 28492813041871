<script setup>
import {userStore} from "@/store/pinia-stores/user.store";

defineProps({
  btnBackground: {
    type: String,
    default: "",
  },
  isBlur: {
    type: String,
    default: "",
  },
  darkMode: {
    type: Boolean,
    default: false,
  },
  isBtn: {
    type: String,
    default: "bg-gradient-light",
  },
});

const store = userStore();
</script>

<template>
  <nav
      class="navbar navbar-expand-lg top-0 z-index-3 position-absolute mt-4"
      :class="isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100'"
  >
    <div class="container-fluid">
      <router-link
          class="navbar-brand font-weight-bolder ms-lg-0 ms-3"
          :class="darkMode ? 'text-black' : 'text-white'"
          to="/"
      >
        Central Achat
      </router-link>
      <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item d-lg-none">
            <router-link
                to="/signup"
                class="btn btn-sm mb-0 me-1"
                :class="isBtn"
            >
              Nouvel admin (agent)
            </router-link>
          </li>
          <li class="nav-item" v-if="store.user">
            <router-link
                class="nav-link d-flex align-items-center me-2 active"
                aria-current="page"
                to="/dashboard-default"
            >
              <i
                  class="fa fa-chart-pie opacity-6 me-1"
                  aria-hidden="true"
                  :class="isBlur ? 'text-dark' : 'text-white'"
              ></i>
              Tableau de bord
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav d-none d-lg-block">
          <li class="nav-item">
            <router-link
                to="/signup"
                class="btn btn-sm mb-0 me-1"
                :class="isBtn"
            >
              Nouvel admin (agent)
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
