<script setup>
import FormButton from "@/components/FormButton.vue";
import {setApprobationCommande} from "@/services-apis/commandes";
import {computed, ref, watchEffect} from "vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import {useAcceptReasons, useCommande} from "@/services-apis/commandes/hooks.commandes";
import {userStore} from "@/store/pinia-stores/user.store";
import FormInput from "@/components/FormInput.vue";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";

const {refetch, commande} = useCommande()
const {reasons} = useAcceptReasons()
const store = commandeStore()
const ustore = userStore()

// Etape suivante
const next = ref(false)

// Prix des frais de service
const price = ref("")
const computedPrice = computed(() => commande.value?.price)

const closer = ref(null)

// La raison choisie
const computedReason = computed(() => commande.value?.acceptReason)
const reasonChoosed = ref(computedReason.value)
watchEffect(() => {
  price.value = computedPrice.value
  reasonChoosed.value = computedReason.value
})

// Aller de l'avant
const nextFunction = () => {
  if (!reasonChoosed.value)
    toast.error('Choisissez d\'abord une raison valable', {
      position: 'bottom-center',
      hideProgressBar: true,
      bodyClassName: 'body-toaster',
      toastStyle: Styles.toastStyle,
      autoClose: 3000,
    })
  else
    next.value = !next.value
}


/*Lancer la recherche*/
const handleSetApprobation = () => {
  if (Number(price.value) < 10) {
    // Message du succès d'enregistrement user / admin
    return toast.error('Donnez un price valide', {
      position: 'bottom-center',
      hideProgressBar: true,
      bodyClassName: 'body-toaster',
      toastStyle: Styles.toastStyle,
      autoClose: 2000,
    })
  }

  setApprobationCommande({
    approbation: true,
    price: price.value,
    adminId: ustore.user?.id,
    reasonId: reasonChoosed.value?.id,
  }, refetch, "Statut mis à jour",() => {
  })
}
</script>

<!--Le composant qui lance les démarches -->
<template>
  <!-- Modal -->
  <div class="modal fade" id="removeRevokeTakeStepCommande" tabindex="-1"
       aria-labelledby="removeRevokeTakeStepCommandeLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <div class="modal-header">
          <div class="col">
            <h5 class="modal-title text-dark" id="removeRevocationModalLabel">Modifier le statut de la commande</h5>
            <span class="text-xs text-bold text-dark">{{ commande?.title }}</span>
          </div>
          <!--          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
        </div>

        <div class="modal-body">

          <p class="text-dark font-weight-normal text-xs">
            Cette commande a été signaler de: "<span
              class="text-success text-xs text-bold">{{ commande?.revokeReason?.title }}</span>"
            Vous êtes sur le point de changer cet état.
          </p>

          <!--          La raison de la révocation-->
          <p class="text-dark text-bold text-sm">Donnez la raison de ce changement (acceptation):</p>
          <div class="flex flex-row flex-wrap center">

            <div v-for="reason in reasons" :key="reason?.id"
                 @click="reasonChoosed=reason"
                 class="btn btn-xs border-radius-xl m-1 border border-light">
              <i v-if="reasonChoosed?.id === reason?.id" class="fa fa-check text-success" aria-hidden="true"></i>
              <span class="text-xs text-dark m-1">{{ reason?.title }}</span>
            </div>
          </div>

          <!-- ======Les details de la raison de revocation ===========-->
          <div v-if="commande?.revokeReason" class="collapse" id="collapsePurchargPrice">
            <div class="mt-2">
              <strong class="text-bold text-sm text-dark">Donnez le montant des frais de service</strong>
              <form-input type="number" v-model="price" placeholder="Prix total des frais de service (min: 10$)"
                          class="w-75"/>
            </div>
          </div>
          <!--========================================================-->

          <br>
          <p class="text-dark text-bold text-sm">
            Ce que l'auteur<span class="text-gradient text-dark font-weight-normal">(client)</span> verra:</p>
          <ul>
            <li class="text-dark text-xs mb-1">Commande en attente de paiement de frais de service</li>
            <li v-if="reasonChoosed" class="text-dark text-xs mb-1">Certains articles de cette commande sont:
              "{{ reasonChoosed?.title }}".
            </li>
          </ul>

          <span class="text-dark font-weight-normal text-xs text-center">
              Vous et d'autres admins, pourrez changer cette action quand vous voulez!
          </span>

          <div class="modal-footer">


            <div class="flex align-items-end">
              <form-button
                  v-if="!next"
                  ref="closer" class="text-xs bg-danger m-1 font-weight-normal btn-sm border-radius-xl"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close">
                Annuler
              </form-button>

              <!-- Faire suivant pour afin afficher la vue où saisir le price-->
              <form-button
                  @click="nextFunction"
                  v-if="!next"
                  class="text-xs m-1 font-weight-normal btn-sm border-radius-xl"
                  data-bs-toggle="collapse" :data-bs-target="reasonChoosed &&'#collapsePurchargPrice'"
                  aria-expanded="false"
                  :aria-controls="reasonChoosed &&'collapsePurchargPrice'"
              >
                <div v-if="store.isLoading">Go</div>
                Suivant
                <i class="fas fa-arrow-right text-white"></i>
              </form-button>
              <!------------------------------------------------------------>

              <!-- Faire précedent -->
              <form-button
                  v-if="next"
                  @click="next = next ? !next: true"
                  class="text-xs bg-danger m-1 font-weight-normal btn-sm border-radius-xl"
                  data-bs-toggle="collapse" data-bs-target="#collapsePurchargPrice" aria-expanded="false"
                  aria-controls="collapsePurchargPrice"
                  type="button"
              >
                Retour
              </form-button>

              <!-- Faire suivant pour afin afficher la vue où saisir le price-->
              <form-button
                  v-if="next"
                  @click="handleSetApprobation"
                  class="text-xs m-1 font-weight-normal btn-sm border-radius-xl">
                <div v-if="store.isLoading">Go</div>
                Terminer maintenant
                <i class="fas fa-check text-white"></i>
              </form-button>
              <!------------------------------------------------------------>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>
