<script setup>
import {usePayements} from "@/services-apis/payements/hooks.payements";
import dayjs from "dayjs";
import DropdownOptionsPayement from "@/views/components/DropdownOptionsPayement.vue";
import {computed, ref} from "vue";
import validator from "validator";

const typeText = (pymnt) => {
  return pymnt?.type === "paye_purcharge_fee" ? 'Frais service'
      : pymnt?.type === 'acompte_commande' ? 'Paiement acompte'
          : pymnt?.type === 'buy_article' ? 'Achat article' : pymnt?.type
}

const search = ref('')
const {payements} = usePayements()
const resultSearch = computed(() => payements.value?.filter(item => (
    validator.contains(item?.command?.title, search.value, {ignoreCase: true})
    ||
    validator.contains(typeText(item), search.value, {ignoreCase: true})
    ||
    validator.contains(item?.user?.firstname, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.user?.lastname, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.user?.email, search.value, {ignoreCase: true})
)))

</script>

<template>
  <div class="card mb-4 border-radius-md">

    <div class="d-flex justify-content-between">
      <div class="card-header pb-0">
        <h6>Facturations (paiements)</h6>
      </div>

      <input
          type="text"
          class="form-control m-3" style="width: 200px"
          v-model="search"
          placeholder="Tapez un mot clé pour faire la recherche"
      />

    </div>

    <div class="card-body px-0 pt-0 pb-2">

      <!--    Si aucun payements n'est trouvé-->
      <div v-if="resultSearch?.length === 0" class="text-center">
        <!--      Aucun payement enregistré-->
        <div>
          <img src="@/assets/img/paye.jpg" alt="" class="img-fluid pointer-none" style="height: 300px;width: 300px"/>
          <p class="text-normal text-sm text-dark">Aucun paiement enregistré pour le moment</p>
        </div>
      </div>

      <div v-else class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
          <tr>
            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Objectif
            </th>
            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Cible
            </th>

            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Budget
            </th>
            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Date de paiement
            </th>
            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              Client
            </th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="payement in resultSearch" :key="payement?.id">
            <td>
              <div class="d-flex px-2">

                <i class="fas fa-file-invoice m-2 bg-white text-success shadow-sm p-2 rounded-circle d-flex justify-content-center"
                   style="height: 33px;width: 33px"></i>

                <div class="my-auto">
                  <h6 class="mb-0 text-sm text-truncate" style="width: 10rem">{{ typeText(payement) }}</h6>
                </div>
              </div>
            </td>

            <td>
              <div class="d-flex px-2">
                <div class="my-auto">
                  <h6 class="mb-0 text-xs text-truncate" style="width: 12rem">
                    {{ payement?.command?.title || 'Achat d\'articles' }}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm font-weight-bold mb-0">{{ payement?.devise?.symbole }} {{ payement?.totalPayed }}</p>
            </td>
            <td>
              <span class="text-xs font-weight-bold">{{
                  dayjs(payement?.payedAt).locale('fr').format('DD MMM YYYY')
                }}</span>
            </td>
            <td class="align-middle text-center">
              <div class="d-flex align-items-center justify-content-center">
                <strong class="text-xs">{{ payement?.user?.firstname || payement?.user?.email }}
                  {{ payement?.user?.lastname }}</strong>
              </div>
            </td>

            <td class="align-middle">
              <dropdown-options-payement :payement="payement"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
