<script setup>
import CreditCardPayement from "@/examples/Cards/CreditCardPayement.vue";
import DefaultInfoCreditCardPayement from "@/examples/Cards/DefaultInfoCreditCardPayement.vue";
import PayementCard from "@/views/components/PayementCard.vue";
import FacturesPayementCard from "@/views/components/FacturesPayementCard.vue";
import MoreDetailPayementCard from "@/views/components/MoreDetailPayementCard.vue";
import TransactionsCommandeCard from "@/views/components/TransactionsCommandeCard.vue";
import {usePayement} from "@/services-apis/payements/hooks.payements";
import AgentDetailCardPayement from "@/examples/Cards/AgentDetailCardPayement.vue";
import Loading from "@/views/components/Loading.vue";

const {payement, isLoading} = usePayement()

</script>
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8">
        <div class="row mt-4">
          <Loading v-if="isLoading"/>
          <div class="col-xl-6 mb-xl-0 mb-4">
            <!-- Afficher soit l'agent, soit la carte de crédit, selon que le payement soit fait en ligne / présentiel-->
            <component :is="payement?.place === 'online' ? CreditCardPayement : AgentDetailCardPayement"/>
          </div>
          <div class="col-xl-6">
            <div class="row">
              <div class="col-md-6">
                <default-info-credit-card-payement
                    :icon="{
                    component: 'fas fa-landmark',
                    background: 'bg-gradient-success',
                  }"
                    title="Total"
                    description="Le prix maximum à payer"
                    :value="payement?.devise?.symbole+ payement?.total"
                />
              </div>
              <div class="col-md-6">
                <default-info-credit-card-payement
                    :icon="{
                    component: 'fab fa-paypal',
                    background: 'bg-gradient-success',
                  }"
                    title="Payé"
                    description="Total payé pour cette fois"
                    :value="payement?.devise?.symbole+ payement?.totalPayed"
                />
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-4">
            <payement-card/>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <factures-payement-card class="mt-4"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <more-detail-payement-card/>
      </div>
      <div class="col-md-5">
        <transactions-commande-card/>
      </div>
    </div>
  </div>
</template>
