import {toast} from "vue3-toastify";
import api from "@/services-apis/axios-init";
import {userStore} from "@/store/pinia-stores/user.store";
import {Styles} from "@/assets/js/styles";
import router from "@/router";


// S'enregistrer avec son email et son mot de passe
export const createWithAndPassword = (email, password, firstname) => {

    const storeUser = userStore()
    // Chargement
    storeUser.setIsLoading(true)

    let body = {email, password, firstname}

    // Se connecter avec son mot de passe et email
    api.post('/admin/create-by-email-and-password', body).then(res => {

        // Arrêter le chargement
        storeUser.setIsLoading(false)
        // console.log(res)

        // Sauvegarde des données dans le store et en localStorage
        let user = res.data.user
        delete user.password
        // ============ Tokens =============
        let tokens = res.data.tokens

        // Store user: sauvegarde
        storeUser.setUserInfos(user, tokens)

        // Message du succès d'enregistrement user / admin
        toast.success('Compte crée avec succès', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
            onClose: () => router.push('/')
        })

    }).catch(err => {

        // Arrêter le chargement
        storeUser.setIsLoading(false)
        let msg = err.response.data.message

        toast.error(msg || 'impossible de créer le compte maintenant', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}


// Se connecter par email et mot de passe
export const _loginWithAndPassword = (email, password, remember = false) => {

    const store = userStore()
    let body = {email: email, password: password}
    // Chargement
    store.setIsLoading(true)

    // Se connecter avec son mot de passe et email
    api.post('/admin/login-by-email-and-password', body).then(res => {

        // Arrêter le chargement
        store.setIsLoading(false)
        // console.log(res)

        // Sauvegarde des données dans le store et en localStorage
        let user = res.data.user
        delete user.password
        // ============ Tokens =============
        let tokens = res.data.tokens

        // Store user: sauvegarde
        store.setUserInfos(user, tokens, remember)

        // Message du succès de connexion user / admin
        toast.success('Authentification réussie', {
            position: 'bottom-left',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 1000,
            onClose: () => router.push('/')
        })
    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)
        let msg = err.response.data.message

        toast.error(msg || 'Identifiants incorrect, vous ne pouvez pas vous connecter', {
            position: 'bottom-left',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})
    })
}


// Mdofier les informations du profil
export const _updateProfile = (data, callback) => {

    const store = userStore()

    // Chargement
    store.setIsLoading(true)
    let headers = {'Authorization': 'Bearer ' + store.tokens.accessToken}

    // Se connecter avec son mot de passe et email
    api.patch('/admin/update', data, {headers}).then(res => {

        let user = res.data.data
        delete user.password

        // Sauvegarde des données dans le store et en localStorage
        // ============ Tokens =============
        let tokens = store.tokens
        let remember = store.rememberMe

        // Store user: sauvegarde
        store.setUserInfos(user, tokens, remember)

        // Arrêter le chargement
        store.setIsLoading(false)
        // console.log(res)

        // Message du succès de connexion user / admin
        toast.success('Modification sauvegardée', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
        })

        callback && callback()
    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)
        let msg = err.response.data.message

        toast.error(msg || 'Vous ne pouvez pas modifier votre profil pour le moment', {
            position: 'bottom-right',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})
    })
}

// Check login
export async function checkUserLoggedIn() {
    try {
        const store = userStore()
        if (store.user)
            return true;

    } catch (error) {
        console.error('Error checking user login status:', error);
        return false;
    }
}
