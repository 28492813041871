<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import {usePayement} from "@/services-apis/payements/hooks.payements";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
defineProps({
  card: {
    type: Object,
    number: String,
    holderName: String,
    expiryDate: String,
    holderText: String,
    expiryText: String,
    background: String,
    default: () => ({
      number: "4562 1122 4594 7852",
      holderName: "Jack Peterson",
      expiryDate: "11/22",
      holderText: "Card Holder",
      expiryText: "Expires",
      background: "dark",
    }),
  },
});

const {payement} = usePayement()

// :style="{
// backgroundImage: 'url(' + require('@/assets/img/card-visa.jpg') + ')',
// }"
</script>
<template>
  <div class="card bg-transparent shadow-xl">
    <div class="overflow-hidden position-relative border-radius-xl">
      <span class="mask" :class="`bg-gradient-success`"></span>
      <div class="card-body position-relative z-index-1 p-3">
        <i class="fas fa-user text-white p-2" aria-hidden="true"></i>
        <h5 class="text-white mt-4 mb-5 pb-2">
          Informations sur l'agent
        </h5>
        <div class="d-flex">
          <div class="d-flex">
            <div :class="isRTL ? 'ms-4' : 'me-4'">
              <p class="text-white text-sm opacity-8 mb-0">
                Agent
              </p>
              <h6 class="text-white text-truncate text-sm mb-0" style="max-width: 190px">{{ payement?.agent?.firstname || payement?.agent?.email }} {{payement?.agent?.lastname}}</h6>
            </div>
            <div>
              <p class="text-white text-sm opacity-8 mb-0">
                Code
              </p>
              <h6 class="text-white text-truncate text-sm mb-0" style="max-width: 80px">CA-U{{ payement?.agent?.id}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
