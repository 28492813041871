<script setup>
import ArgonButton from "@/components/FormButton.vue";
import {usePayement} from "@/services-apis/payements/hooks.payements";
import dayjs from "dayjs";
import {computed} from "vue";
import {payementStore} from "@/store/pinia-stores/payements-store";

const {commande, refetch} = usePayement()
const typeText = (pymnt) => {
  return pymnt?.type === "paye_purcharge_fee" ? 'Frais service'
      : pymnt?.type === 'acompte_commande' ? 'Paiement acompte' :
          pymnt?.type
}

const textLengthSoldeCommande = computed(() => {
  let elements = commande.value?.payements.filter(item => item?.type === 'solde_commande')
  let length = elements?.length
  return length === 0
})

const textLengthPurchargeFee = computed(() => {
  let elements = commande.value?.payements.filter(item => item?.type === 'paye_purcharge_fee')
  let length = elements?.length
  return length === 0
})


const textLengthAcompteCommande = computed(() => {
  let elements = commande.value?.payements.filter(item => item?.type === 'acompte_commande')
  let length = elements?.length
  return length === 0
})



// Aller à un autre payement
const strPym = payementStore()
const gotoOnePayement = async(pymnt) => {
  strPym.setCurrentId(pymnt?.id)
  await refetch()
}

</script>

<template>
  <div class="card h-100 mb-4">
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-md-12">
          <h6 class="mb-0">Transactions</h6>
          <span class="text-xs">Transactions sur la commande de ce paiement</span>
        </div>
      </div>
    </div>
    <div class="card-body pt-4 p-3">
      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Frais de services
      </h6>
      <span class="text-xs mx-5 text-center align-self-center" v-if="textLengthPurchargeFee">Aucun solde payé</span>

      <div v-if="commande?.payements.length===0" class="text-center">
        <img src="@/assets/img/paye.jpg" alt="" class="img-fluid" style="height: 100px;width: 100px"/>
        <p class="text-normal text-sm text-dark">Aucun paiement enregistré pour la commande</p>
      </div>

      <ul class="list-group">

        <!-- Les frais de services-->
        <template v-for="payement in commande?.payements" :key="payement.id">
          <li
              @click="gotoOnePayement(payement)"
              v-if="payement?.type === 'paye_purcharge_fee'"
              class="btn shadow-none text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
          >
            <div class="d-flex align-items-center">
              <argon-button
                  color="success"
                  variant="outline"
                  size="sm"
                  class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-arrow-up" aria-hidden="true"></i>
              </argon-button>

              <div class="d-flex flex-column">
                <h6 class="mb-1 text-dark text-sm">{{ typeText(payement) }}</h6>
                <span class="text-xs text-start">{{ dayjs(payement?.createdAt).format('DD MMM, à HH:mm') }}</span>
              </div>
            </div>

            <div
                class="d-flex align-items-center text-success text-gradient text-lg font-weight-bold"
            >
              + {{ payement?.devise?.symbole }} {{ payement?.totalPayed }}
            </div>
          </li>
        </template>
      </ul>

      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Acompte de la commande
      </h6>
      <span class="text-xs mx-5 text-center align-self-center"
            v-if="textLengthAcompteCommande">Aucun solde payé</span>

      <ul class="list-group">

        <!--  Acompte de la commande -->
        <template v-for="payement in commande?.payements" :key="payement.id">
          <li
              @click="gotoOnePayement(payement)"
              v-if="payement?.type === 'acompte_commande'"
              class="btn shadow-none text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
          >
            <div class="d-flex align-items-center">
              <argon-button
                  color="success"
                  variant="outline"
                  size="sm"
                  class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-arrow-up" aria-hidden="true"></i>
              </argon-button>

              <div class="d-flex flex-column">
                <h6 class="mb-1 text-dark text-sm">{{ typeText(payement) }}</h6>
                <span class="text-xs text-start">{{ dayjs(payement?.createdAt).format('DD MMM, à HH:mm') }}</span>
              </div>

            </div>
            <div
                class="d-flex align-items-center text-success text-gradient text-lg font-weight-bold"
            >
              + {{ payement?.devise?.symbole }} {{ payement?.totalPayed }}
            </div>
          </li>
        </template>
      </ul>


      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Solde de la commande
      </h6>
      <span class="text-xs mx-5 text-center align-self-center" v-if="textLengthSoldeCommande">Aucun solde payé</span>

      <ul class="list-group">
        <!--   Solde de la commande -->
        <template v-for="payement in commande?.payements" :key="payement.id">
          <li
              @click="gotoOnePayement(payement)"
              v-if="payement?.type === 'solde_commande'"
              class="btn shadow-none text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
          >
            <div class="d-flex align-items-center">
              <argon-button
                  color="success"
                  variant="outline"
                  size="sm"
                  class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-arrow-up" aria-hidden="true"></i>
              </argon-button>

              <div class="d-flex flex-column">
                <h6 class="mb-1 text-dark text-sm">{{ typeText(payement) }}</h6>
                <span class="text-xs text-start">{{ dayjs(payement?.createdAt).format('DD MMM, à HH:mm') }}</span>
              </div>

            </div>
            <div
                class="d-flex align-items-center text-success text-gradient text-lg font-weight-bold"
            >
              + {{ payement?.devise?.symbole }} {{ payement?.totalPayed }}
            </div>
          </li>
        </template>
      </ul>


    </div>
  </div>
</template>
