import {userStore} from "@/store/pinia-stores/user.store";
import api from "@/services-apis/axios-init";
import {useQuery} from "@tanstack/vue-query";

// Utiliser tous les articles
export const useArticles = () => {

    // Tous les articles
    const getArticles = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get('/admin/articles/', {headers})
        return res?.data?.data || []
    }

    const {
        data: articles, isPending, isLoading, isError, error
    } = useQuery({
        queryKey: ['get-all-articles'], queryFn: getArticles, refetchInterval: 3000
    })

    // console.log({commandes})

    return {
        isPending, isLoading, isError, articles, error
    }
}

