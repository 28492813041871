<script setup>
import ArticlesList from './ArticlesList.vue';
import {useArticles} from "@/services-apis/articles/hooks.articles";
const {articles, isLoading} = useArticles()
</script>

<template>
  <div class="col-lg-5">
    <articles-list
        title="Articles des commandes"
        :articles="articles?.slice(0, 6)"
        :loading="isLoading"
    />
  </div>
</template>

<style scoped>

</style>
