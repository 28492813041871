<script setup>
import CommandesTable from "@/views/components/CommandesTable.vue";
import Loading from "@/views/components/Loading.vue";
import {useCommandes} from "@/services-apis/commandes/hooks.commandes";
const {isLoading} = useCommandes()

</script>
<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <commandes-table />
        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>
