<script setup>
import {onBeforeMount, onMounted, onBeforeUnmount, ref, watchEffect, computed} from "vue";
import {useStore} from "vuex";

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ProfileCard from "./components/ProfileCard.vue";
import ArgonButton from "@/components/FormButton.vue";
import {useUser} from "@/services-apis/users/hooks.user";
import {_updateProfile} from "@/services-apis/users";
import {DatePicker} from "ant-design-vue";
import 'dayjs/locale/fr'
import locale from 'ant-design-vue/es/date-picker/locale/fr_FR';
import dayjs from "dayjs";
import {userStore} from "@/store/pinia-stores/user.store";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const user_store = userStore();

onMounted(() => {
  store.state.isAbsolute = true;
  setNavPills();
  setTooltip();
});
onBeforeMount(() => {
  store.state.imageLayout = "profile-overview";
  store.state.showNavbar = false;
  store.state.showFooter = true;
  store.state.hideConfigButton = true;
  body.classList.add("profile-overview");
});
onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.imageLayout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = false;
  body.classList.remove("profile-overview");
});

// L'admin en cours
const {user, refetch} = useUser()

const disabledDate = () => {
  const today = dayjs();
  const tomorrow = today.add(1, "d");
  return tomorrow < today
}

const firstname = ref('')
const lastname = ref('')
const birthday = ref('')
const gender = ref('')

const username = computed(() => {
  return `${firstname.value || ''} ${lastname.value || ''}`;
});

const refetchDataUser = async () => {
  await refetch()
}

const handleUpdate = () => {

  _updateProfile({
    username: username.value,
    firstname: firstname.value,
    lastname: lastname.value,
    birthday: birthday.value,
    gender: gender.value
  }, refetchDataUser)

}

watchEffect(() => {
  if (user.value) {
    username.value = user.value.firstname + ' ' + user.value.lastname || "";
    firstname.value = user.value.firstname;
    lastname.value = user.value.lastname;
    gender.value = user.value.gender;
    birthday.value = user.value.birthday || dayjs();
  }
});


</script>
<template>
  <a-config-provider :locale="locale">
    <main>
      <div class="container-fluid">
        <div
            class="page-header min-height-300"
            style="
          background-image: url(&quot;https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80&quot;);
          margin-right: -24px;
          margin-left: -34%;
        "
        >
          <span class="mask bg-gradient-success opacity-6"></span>
        </div>
        <div class="card shadow-lg mt-n6 border-radius-md">
          <div class="card-body p-3">
            <div class="row gx-4">
              <div class="col-auto">
                <div class="avatar avatar-xl position-relative">
                  <img
                      src="../assets/img/team-1.jpg"
                      alt="profile_image"
                      class="shadow-sm w-100 border-radius-lg"
                  />
                </div>
              </div>
              <div class="col-auto my-auto">
                <div class="h-100">
                  <h5 class="mb-1">{{ user?.firstname || 'Aucun prénom' }} {{ user?.lastname }}</h5>
                  <p class="mb-0 font-weight-bold text-sm">Ref: {{ user?.uuid }}</p>
                  <p class="mb-0 font-weight-bold text-sm">Code: CA-U{{ user?.id }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-4 container-fluid ">
        <div class="row">
          <div class="col-md-8">
            <div class="card border-radius-md">
              <div class="card-header pb-0">
                <div class="d-flex align-items-center">
                  <p class="mb-0 text-lg font-weight-bold">Modifier Profil</p>
                  <argon-button @click="handleUpdate" color="success" size="sm" class="ms-auto">{{
                      user_store.isLoading ? 'Sauvegarde ...' : 'Sauvegarder'
                    }}
                  </argon-button>
                </div>
              </div>
              <div class="card-body">
                <p class="text-uppercase text-sm">Vos informations personnelles</p>
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label"
                    >Nom d'utilisateur</label
                    >
                    <input class="form-control" type="text" :value="username" readonly/>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label"
                    >Adresse E-mail</label
                    >
                    <input class="form-control" type="email" :value="user?.email" readonly/>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label"
                    >Prénom</label
                    >
                    <input class="form-control" type="text" v-model="firstname"/>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label"
                    >Postnom</label
                    >
                    <input class="form-control" type="text" v-model="lastname"/>
                  </div>
                </div>
                <hr class="horizontal dark"/>
                <p class="text-uppercase text-sm">Autres informations</p>
                <div class="row">

                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                    >Anniversaire</label
                    >
                    <date-picker :disabled-date="disabledDate" class="form-control" format="DD / MM / YYYY"
                                 :locale="locale" :value="dayjs(birthday)" @change="value=>birthday =value"/>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                    >Genre</label
                    >
                    <select class="form-select" aria-label="Select gender" v-model="gender">
                      <option value="M" selected>Masculin</option>
                      <option value="F">Féminin</option>
                    </select>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <profile-card/>
          </div>
        </div>
      </div>
    </main>
  </a-config-provider>
</template>
