<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import Loading from "@/views/components/Loading.vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import DetailArticle from "@/views/modals/DetailArticle.vue";

const cStore = commandeStore();
const store = useStore();
const isRTL = computed(() => store.state.isRTL);

defineProps({
  title: {
    type: String,
    default: "Articles",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  articles: {
    type: Array,
  }
});

</script>
<template>
  <div class="card">
    <div class="d-flex justify-content-between p-3 pb-0 card-header">
      <h6 class="mb-0">{{ title }}</h6>
      <Loading v-if="loading"/>
    </div>

    <div v-if="articles?.length===0" class="d-flex justify-content-center align-items-center flex-column">
      <img src="@/assets/img/illustrations/humain-and-table-vide.jpg"
           alt="empty illustration" class="img img-fluid"/>
      <span class="text-gray my-3 text-sm">Aucun nouvel article actuellement</span>
    </div>

    <div v-else class="p-3 card-body">
      <ul :class="`list-group ${isRTL ? 'pe-0' : ''}`">
        <li
            @click="cStore.setCurrentArticle(article)" data-bs-toggle="modal"
            data-bs-target="#detailArticleModal"
            v-for="( article, index) of articles"
            :key="index"
            :class="`mb-2 border-0 list-group-item cursor-pointer d-flex justify-content-between border-radius-lg
          ${isRTL ? 'pe-0' : 'ps-0'}`"
        >
          <div class="d-flex align-items-center">
            <div
                :class="`text-center shadow icon icon-shape icon-sm bg-gradient-${background} ${
                isRTL ? 'ms-3' : 'me-3'
              }`"
            >
              <i class="fas fa-check"></i>
            </div>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-sm text-dark">{{ article?.name }}</h6>
              <span class="text-xs text-truncate" style="max-width: 24vw">{{
                  article?.description
                }} {{ article?.description }}{{ article?.description }}{{ article?.description }}</span>
            </div>
          </div>
          <div class="d-flex">
            <button
                class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
            >
              <i
                  :class="`ni ${isRTL ? 'ni-bold-left' : 'ni-bold-right'}`"
                  aria-hidden="true"
              ></i>
            </button>
          </div>
        </li>
      </ul>
    </div>

    <!--    Modal sur les articles-->
    <DetailArticle/>
  </div>
</template>
