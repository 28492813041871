<script setup>
import {userStore} from "@/store/pinia-stores/user.store";
import {computed} from "vue";
import dayjs from "dayjs";

const store = userStore()
const user = computed(() => store.user)
const age = computed(() => dayjs().year() - dayjs(user.value?.birthday).year() || "")

</script>

<template>
  <div class="card card-profile border-radius-md">
    <img
        src="../../assets/img/bg-profile.jpg"
        alt="Image placeholder"
        class="card-img-top border-radius-md"
    />
    <div class="row justify-content-center">
      <div class="col-4 col-lg-4 order-lg-2">
        <div class="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
          <a href="javascript:;">
            <img
                src="../../assets/img/team-2.jpg"
                class="rounded-circle img-fluid border border-2 border-white"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="card-header text-center border-0 pt-0 pt-lg-2 pb-4 pb-lg-3">
      <div class="d-flex justify-content-center align-items-center">

        <button @click="store.disconnect()"
                class="btn btn-sm btn-dark float-right mb-0  d-lg-block"
        >Déconnexion
        </button
        >

      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <div class="d-grid text-center">
              <span class="text-lg font-weight-bolder">22</span>
              <span class="text-sm opacity-8">Commandes</span>
            </div>
            <div class="d-grid text-center mx-4">
              <span class="text-lg font-weight-bolder">10</span>
              <span class="text-sm opacity-8">Paiements</span>
            </div>
            <div class="d-grid text-center">
              <span class="text-lg font-weight-bolder">89</span>
              <span class="text-sm opacity-8">Factures</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4">
        <h5>
          {{ user?.firstname || '-' }} {{ user?.lastname || '-' }}
          <span class="text-success" v-if="age !== ''">, {{ age }} {{ age > 1 ? "ans" : 'an' }}</span>
        </h5>
        <p class="h6 font-weight-300">
          <i class="ni location_pin mr-2"></i>Sexe : {{ user?.gender }}
        </p>
        <p class="h6 font-weight-300">
          <i class="ni location_pin mr-2"></i>Poste : Receptioniste
        </p>
        <div class="h6 mt-4">
          <i class="ni business_briefcase-24 mr-2"></i>Travailleur -
          Central d'achat
        </div>
        <div>
          <i class="ni education_hat mr-2"></i>Siège de Kinshasa, Congo
        </div>
      </div>
    </div>
  </div>
</template>
