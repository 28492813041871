<script setup>
import {useCommandes} from "@/services-apis/commandes/hooks.commandes";
import DropdownOptionsCommandes from "@/views/components/DropdownOptionsCommandes.vue";
import TakeStepCommande from "@/views/modals/TakeStepCommande.vue";
import {computed, ref} from "vue";
import validator from "validator";

const {commandes} = useCommandes()

const search = ref('')
const resultSearch = computed(() => commandes.value?.filter(item => (
    validator.contains(item?.title, search.value, {ignoreCase: true})
        ||
        validator.contains(item?.creator?.firstname, search.value, {ignoreCase: true})
        ||
        validator.contains(item?.creator?.lastname, search.value, {ignoreCase: true})
        ||
        validator.contains(item?.creator?.email, search.value, {ignoreCase: true})
    )))

</script>

<template>
  <div class="card mb-4 border-radius-md">

    <div class="d-flex justify-content-between">
      <div class="card-header pb-0">
        <h6>Toutes les commandes</h6>
      </div>

      <input
          type="text"
          class="form-control m-3" style="width: 200px"
          v-model="search"
          placeholder="Tapez un mot clé pour faire la recherche"
      />
    </div>

    <div v-if="commandes?.length === 0" class="d-flex justify-content-center align-items-center flex-column">
      <img src="@/assets/img/illustrations/commande-vide.jpg"
           alt="empty illustration" class="img img-fluid" style="width: 35vw;height: 30vw"/>
      <span class="text-gray my-3 text-sm">Aucune commande actuellement</span>
    </div>


    <div v-else class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
          <tr>
            <th

                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Titre
            </th>

            <th

                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              client <span class="text-capitalize">(Auteur)</span>
            </th>

            <th

                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Budget
            </th>
            <th

                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Articles
            </th>

            <!--            <th

                            class="text-start text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
                        >
                          Progression
                        </th>-->


            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="commande in resultSearch" :key="commande?.id">
            <td>
              <div class="d-flex">
                <div>
                  <img
                      src="../../assets/img/illustrations/cart.jpg"
                      class="avatar avatar-sm rounded-circle me-2"
                      alt="icône commande"
                  />
                </div>
                <div class="my-auto">
                  <h6 class="mb-0 text-sm text-truncate" style="width: 250px">{{ commande?.title }}</h6>
                  <span class="mb-0 text-xs text-success font-weight-normal">{{ commande?.status }}</span>

                </div>
              </div>
            </td>

            <td>
              <p class="text-xs mb-0">{{ commande?.creator?.firstname || commande?.creator?.email }}
                {{ commande?.creator?.lastname }}</p>
            </td>

            <td>
              <p class="text-xs text-truncate font-weight-bold mb-0" style="width: 120px">
                {{ `${commande?.totalAPayer ? '$ ' + commande?.totalAPayer : 'Non défini'}` }}
              </p>
            </td>

            <td>
              <div class="flex flex-row">
                <div v-for="article in commande?.articles.slice(0, 2)" :key="article?.id" class="d-inline">
                  <img
                      src="../../assets/img/small-logos/logo-spotify.svg"
                      class="avatar avatar-sm rounded-circle"
                      alt="spotify"
                  />
                </div>

                <i class="fas fa-eye"/>
              </div>
            </td>

            <!--
                        <td class="text-start text-center">
                          <div class="d-flex align-items-center justify-content-center">
                            <span class="me-2 text-xs font-weight-bold">60%</span>
                            <div>
                              <div class="progress">
                                <div
                                    class="progress-bar bg-gradient-info"
                                    role="progressbar"
                                    aria-valuenow="60"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style="width: 60%"
                                ></div>s
                              </div>
                            </div>
                          </div>
                        </td>-->

            <td class="text-start">
              <dropdown-options-commandes :commande="commande"/>
            </td>
          </tr>


          <!--            ======================================================-->
          <!--            ======================================================-->
          <!--          <tr>
                      <td>
                        <div class="d-flex px-2">

                          <div>
                            <img
                                src="../../assets/img/small-logos/logo-invision.svg"
                                class="avatar avatar-sm rounded-circle me-2"
                                alt="invision"
                            />
                          </div>
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">Invision</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-bold mb-0">$5,000</p>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">done</span>
                      </td>
                      <td class="text-start text-center">
                        <div class="d-flex align-items-center justify-content-center">
                          <span class="me-2 text-xs font-weight-bold">100%</span>
                          <div>
                            <div class="progress">
                              <div
                                  class="progress-bar bg-gradient-success"
                                  role="progressbar"
                                  aria-valuenow="100"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style="width: 100%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-start">
                        <button
                            class="btn btn-link text-secondary mb-0"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex px-2">
                          <div>
                            <img
                                src="../../assets/img/small-logos/logo-jira.svg"
                                class="avatar avatar-sm rounded-circle me-2"
                                alt="jira"
                            />
                          </div>
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">Jira</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-bold mb-0">$3,400</p>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">canceled</span>
                      </td>
                      <td class="text-start text-center">
                        <div class="d-flex align-items-center justify-content-center">
                          <span class="me-2 text-xs font-weight-bold">30%</span>
                          <div>
                            <div class="progress">
                              <div
                                  class="progress-bar bg-gradient-danger"
                                  role="progressbar"
                                  aria-valuenow="30"
                                  aria-valuemin="0"
                                  aria-valuemax="30"
                                  style="width: 30%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-start">
                        <button
                            class="btn btn-link text-secondary mb-0"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex px-2">
                          <div>
                            <img
                                src="../../assets/img/small-logos/logo-slack.svg"
                                class="avatar avatar-sm rounded-circle me-2"
                                alt="slack"
                            />
                          </div>
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">Slack</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-bold mb-0">$1,000</p>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">canceled</span>
                      </td>
                      <td class="text-start text-center">
                        <div class="d-flex align-items-center justify-content-center">
                          <span class="me-2 text-xs font-weight-bold">0%</span>
                          <div>
                            <div class="progress">
                              <div
                                  class="progress-bar bg-gradient-success"
                                  role="progressbar"
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="0"
                                  style="width: 0%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-start">
                        <button
                            class="btn btn-link text-secondary mb-0"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex px-2">
                          <div>
                            <img
                                src="https://demos.creative-tim.com/argon-dashboard/assets/img/small-logos/logo-webdev.svg"
                                class="avatar avatar-sm rounded-circle me-2"
                                alt="webdev"
                            />
                          </div>
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">Webdev</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-bold mb-0">$14,000</p>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">working</span>
                      </td>
                      <td class="text-start text-center">
                        <div class="d-flex align-items-center justify-content-center">
                          <span class="me-2 text-xs font-weight-bold">80%</span>
                          <div>
                            <div class="progress">
                              <div
                                  class="progress-bar bg-gradient-info"
                                  role="progressbar"
                                  aria-valuenow="80"
                                  aria-valuemin="0"
                                  aria-valuemax="80"
                                  style="width: 80%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-start">
                        <button
                            class="btn btn-link text-secondary mb-0"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex px-2">
                          <div>
                            <img
                                src="../../assets/img/small-logos/logo-xd.svg"
                                class="avatar avatar-sm rounded-circle me-2"
                                alt="xd"
                            />
                          </div>
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">Adobe XD</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-bold mb-0">$2,300</p>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">done</span>
                      </td>
                      <td class="text-start">
                        <div class="d-flex align-items-center justify-content-center">
                          <span class="me-2 text-xs font-weight-bold">100%</span>
                          <div>
                            <div class="progress">
                              <div
                                  class="progress-bar bg-gradient-success"
                                  role="progressbar"
                                  aria-valuenow="100"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style="width: 100%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-start">
                        <button
                            class="btn btn-link text-secondary mb-0"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>-->
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <!--  Les modals -->
  <!--  Ouvrir les demarches d'une commande-->
  <take-step-commande/>

</template>
