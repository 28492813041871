<script setup>
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import dayjs from "dayjs";
import TakeStepCommande from "@/views/modals/TakeStepCommande.vue";
import RevokeTakeStepCommande from "@/views/modals/RevokeTakeStepCommande.vue";
import RemoveRevocationCommande from "@/views/modals/RemoveRevocationCommande.vue";
import {setCommandeIsAvailableNow} from "@/services-apis/commandes";

const {commande, refetch} = useCommande()

const refreshData = async () => {
  await refetch()
}
// Dire ue c'est disponible maintenant
const availableCallback = (state) => {
  setCommandeIsAvailableNow({state: !state}, () => refreshData(), state ? 'Vous êtes revenu à l\'état précédent' : '')
}

</script>

<template>
  <div class="card">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0">Informations sur la commande</h6>
    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group">
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm">Auteur</h6>
            <span class="mb-2 text-sm">
              Nom complet:
              <span class="text-dark font-weight-bold ms-sm-1 text-sm">
                {{ commande?.creator?.firstname || 'Non défini' }} {{ commande?.creator?.lastname }}
              </span>
            </span>
            <span class="mb-2 text-sm">
              Adresse e-mail:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ commande?.creator?.email }}
              </span>
            </span>

          </div>

          <div class="ms-auto text-end">

            <router-link to='#' class="btn btn-link text-dark text-xs px-3 mb-0" href="javascript:;">
              <i class="fas fa-info text-dark me-2" aria-hidden="true"></i
              >Details
            </router-link>

          </div>
        </li>

        <!--        Details textuel de la commande-->
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm">Détails avancés de la commande</h6>
            <span class="mb-2 text-sm">
              Titre:
              <span class="text-dark font-weight-bold ms-sm-1 text-sm">{{ commande?.title }}</span>
            </span>
            <span class="mb-2 text-sm">
              Description:
              <span class="text-dark ms-sm-1 text-xs font-weight-bold"
              >{{ commande?.description || 'Aucune description fournie par le client' }}</span>
            </span>

            <span class="mb-2 text-sm">
              Statut:
              <span class="ms-sm-1 text-sm text-success font-weight-bold"
              >{{ commande?.status }}</span
              >

              <a v-if="commande?.revokeReason" class="btn border border-light btn-xs bg-white m-1 cursor-pointer"
                 data-bs-toggle="collapse" href="#collapseDetailRevokeReason" role="button" aria-expanded="false"
                 aria-controls="collapseDetailRevokeReason"
              >
              <i class="fas fa-question fa-sm"></i>
              </a>

            </span>

            <span v-if="commande?.purcharge_fee" class="mb-2 text-sm">
              Frais de services total:
              <span class="ms-sm-1 text-sm text-success font-weight-bold"
              >{{ commande?.purcharge_fee ? `${'$ ' + String(commande?.purcharge_fee)}` : 'Non défini' }}</span
              >

            </span>

            <span class="text-sm">
              Date:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">{{
                  dayjs(commande?.createdAt).locale('fr').format('DD MMM YYYY')
                }}</span>
            </span>
          </div>

          <div class="ms-auto text-end">

            <!--            Si le programme n'est pas approuver par les admins-->
            <button type="button"
                    v-if="commande?.isWaiting"
                    class="btn btn-link text-dark px-0 mb-0 "
                    data-bs-toggle="modal" data-bs-target="#takeStepCommande"
            >
              <i class="fab fa-opencart me-2" aria-hidden="true"></i>Lancer démarches
            </button>

            <button v-else-if="commande?.isCompletePayed"
                    @click="availableCallback(commande?.isAvailableNow)"
                    type="button"
                    class="btn btn-link text-dark text-xs mb-0 p-0"
                    href="javascript:;">
              <i class="fas fa-check text-dark me-2" aria-hidden="true"></i
              >{{ commande?.isAvailableNow ? 'Pas encore disponible' : 'Disponible' }}
            </button>

            <!--======Lorsqu'on veut appliquer la revocation========= -->
            <button
                type="button"
                data-bs-toggle="modal" data-bs-target="#revokeTakeStepCommande"
                class="btn btn-link text-danger text-xs px-0 mb-0">
              <i class="fas fa-circle-xmark me-2" aria-hidden="true"></i
              >
              <i v-if="commande?.revokeReason " class="fas fa-pen-square" aria-hidden="true"></i>
              {{ commande?.revokeReason ? 'Annulation' : 'Annuler' }}
            </button>
            <!--======================================================-->


            <!--======Lorsqu'on veut annuler la revocation qui a été faite========= -->
            <button
                v-if="commande?.revokeReason"
                type="button"
                data-bs-toggle="modal" data-bs-target="#removeRevokeTakeStepCommande"
                class="btn btn-link text-dark px-0 mb-0 px-2 font-weight-normal" href="javascript:;">
              <i class="fas fa-wrench" aria-hidden="true"></i>
              Modifier statut
            </button>
            <!--======================================================-->

          </div>
        </li>

        <!-- ======Les details de la raison de revocation ===========-->
        <div class="collapse" id="collapseDetailRevokeReason">
          <div v-if="commande?.revokeReason" class="card card-body shadow-none mb-1">
            <span class="font-weight-bold text-dark text-xs">Pourquoi non acceptée ?</span>
            <p class="font-weight-bold text-danger text-md mt-1">{{ commande?.revokeReason?.title }}</p>
            <span class="font-weight-normal text-dark text-xs">{{ commande?.revokeReason?.slug }}</span>
          </div>

          <!--      --------------- Acceptée ensuite -------------------------------->
          <div v-if="commande?.acceptReason" class="card card-body shadow-none mb-1">
            <span class="font-weight-bold text-dark text-xs">Pourquoi acceptée ensuite ?</span>
            <p class="font-weight-bold text-success  text-md mt-1">{{ commande?.acceptReason?.title }}</p>
            <span class="font-weight-normal text-dark text-xs">{{ commande?.acceptReason?.slug }}</span>
          </div>
          <!---------------------------------------------------------------------------------->

        </div>
        <!--========================================================-->

        <li
            class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm">Articles</h6>
            <span class="mb-2 text-sm">
             Disponibles:
              <span class="text-dark font-weight-bold ms-sm-1 text-sm"
              >{{ commande?.articleAvailable }} article(s)
                <!--                <span class="text-dark font-weight-normal ms-sm-1 text-sm">($ 22,34 à payer)</span>-->
              </span>
            </span>
            <span class="mb-2 text-sm">
              En attente:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ commande?.articles.length - commande?.articleAvailable }} article(s)
              </span>
            </span>

            <span class="mb-2 text-sm">
              Total:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold"
              >{{ commande?.articles.length }} article(s)</span
              >
            </span>

            <span class="text-sm">
             Modifiez le prix d'un article pour le rendre disponible au client qui le demande.
            </span>
          </div>
          <!--          <div class="ms-auto text-end">

                      <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;">
                        <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i
                        >Edit
                      </a>
                    </div>-->
        </li>
      </ul>
    </div>
  </div>


  <!--  Les modals -->
  <!--  Ouvrir les demarches d'une commande-->
  <take-step-commande/>

  <!--  Refuser une commande avec sa raison-->
  <revoke-take-step-commande/>

  <!--  Annuler le refus d'une commande en désignant la raison de l'acceptation-->
  <remove-revocation-commande/>
</template>
