<script setup>
import {usePayement} from "@/services-apis/payements/hooks.payements";
import dayjs from "dayjs";
import DropdownConfirmPayement from "@/views/dropdowns/DropdownConfirmPayement.vue";
import DropdownDisabledNotificationPayement from "@/views/dropdowns/DropdownDisabledNotificationPayement.vue";

const relativeTime = require('dayjs/plugin/relativeTime')
const locale = require('dayjs/locale/fr')
dayjs.locale(locale)
dayjs.extend(relativeTime)

const {payement, commande, articles} = usePayement()

const typeText = (pymnt) => {
  return pymnt?.type === "paye_purcharge_fee" ? 'Frais service'
      : pymnt?.type === 'acompte_commande' ? 'Paiement acompte'
          : pymnt?.type === 'buy_article' ? 'Achat article' : pymnt?.type
}
</script>
<template>
  <div class="card">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0">Informations avancées sur le paiement</h6>
    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group">
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column col-8">
            <h6 class="mb-3 text-sm">Motif: <span class="text-primary text-lg">{{ typeText(payement) }}</span></h6>
            <h6 class="mt-3 text-md text-success">Articles et commande</h6>
            <span class="mb-2 text-sm">
              Commande:
              <span class="text-dark font-weight-bold ms-sm-2 text-truncate"
              >{{ commande?.title }}</span
              >
            </span>

            <span class="mb-2 text-sm">
              Articles concernés:
              <span v-for="article in articles" :key="article?.id"
                    class="text-dark ms-sm-2 font-weight-bold">{{ article?.name }},</span>
            </span>

            <h6 class="mt-3 text-md text-success">Plus des détails sur le paiement</h6>
            <span class="mb-2 text-sm">
              Confirmation:
              <span class="text-dark ms-sm-2 font-weight-bold">{{
                  payement?.confirmed ? 'Confirmé' : 'Non confirmé'
                }}</span>
            </span>

            <span class="mb-2 text-sm">
              Lieu:
              <span class="text-dark ms-sm-2 font-weight-bold">{{
                  payement?.place === 'online' ? 'En ligne' : 'En présentiel'
                }}</span>
            </span>

            <span class="mb-2 text-sm">
              Devise (monnaie):
              <span class="text-dark ms-sm-2 font-weight-bold">{{ payement?.devise?.name }}</span>
            </span>

            <span class="mb-2 text-sm">
              Date:
              <span class="text-dark ms-sm-2 font-weight-bold">{{
                  dayjs(payement?.payedAt).format('DD MMM YYYY')
                }}</span>
            </span>

            <span class="mb-2 text-sm">
              Dernière modification:
              <span
                  class="text-dark ms-sm-2 font-weight-bold">{{ dayjs(payement?.updatedAt).format('DD MMM YYYY') }}
                <span class="font-weight-normal text-xs">({{ dayjs(payement?.updatedAt).fromNow() }})
              </span>
              </span>
            </span>

            <span class="mb-2 text-sm">
              Auteur:
              <span class="text-dark ms-sm-2 font-weight-bold">
                {{ payement?.user?.firstname || payement?.user?.email }} {{ payement?.user?.lastname }}
              </span>
            </span>

          </div>

          <div class="ms-auto text-end">
            <dropdown-disabled-notification-payement :payement="payement"/>
            <dropdown-confirm-payement :payement="payement"/>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>
