<script setup>
import {commandeStore} from "@/store/pinia-stores/commande-store";
import router from "@/router";

defineProps(['commande'])

const store = commandeStore();

// Aller à une commande
const goToOne = (id) => {
  store.setCurrentId(id)
  router.push({name: 'Details-Commande'})
}

</script>

<template>
  <!-- Example split danger button -->
  <div class="btn-group">
    <button type="button" class="btn btn-link text-success text-xs pb-0" @click="goToOne(commande?.id)">Voir
      détails
    </button>

    <button type="button" @click="store.setCurrentId(commande?.id)" class="btn btn-link text-dark font-weight-normal text-xs pb-0"
            data-bs-toggle="dropdown"
            aria-expanded="false">
      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
    </button>
    <ul class="dropdown-menu shadow-card">
      <li>
        <button type="button" @click="goToOne(commande?.id)" class="dropdown-item text-xs">
          <i class="fas fa-plus-circle"></i>
          Voir détails
        </button>
      </li>
      <li v-if="commande?.isWaiting">
        <button
            type="button" class="dropdown-item   text-xs"
            data-bs-toggle="modal" data-bs-target="#takeStepCommande"
        >
          <i class="fas fa-check"></i>
          Lancer les démarches
        </button>
      </li>

      <li>
        <hr class="dropdown-divider">
      </li>
      <li><a class="dropdown-item" href="#">Autres actions</a></li>
    </ul>

  </div>

</template>


