import {createRouter, createWebHistory} from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import DetailCommande from "@/views/DetailCommande.vue";
import Commandes from "@/views/Commandes.vue";
import Facturations from "@/views/Facturations.vue";
import DetailPayement from "@/views/DetailPayement.vue";
import {checkUserLoggedIn} from "@/services-apis/users";

const routes = [
    {
        path: '/',
        redirect: "dashboard-default",
        meta: {requiresAuth: true},
    },
    {
        path: "/dashboard-default",
        name: "Dashboard",
        component: Dashboard,
        meta: {requiresAuth: true},
    },
    {
        path: "/commandes",
        name: "Commandes",
        component: Commandes,
        meta: {requiresAuth: true},
    },
    {
        path: "/details",
        name: "Details-Commande",
        component: DetailCommande,
        meta: {requiresAuth: true},
    },
    {
        path: "/facturations",
        name: "Facturations",
        component: Facturations,
        meta: {requiresAuth: true},
    },
    {
        path: "/details-payement",
        name: "DetailsPayement",
        component: DetailPayement,
        meta: {requiresAuth: true},
    },
    {
        path: "/virtual-reality",
        name: "Virtual Reality",
        component: VirtualReality,
        meta: {requiresAuth: true},
    },
    {
        path: "/rtl-page",
        name: "RTL",
        component: RTL,
        meta: {requiresAuth: true},
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {requiresAuth: true},
    },

    {
        path: "/signin",
        name: "Signin",
        component: Signin,
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
    const isLoggedIn = await checkUserLoggedIn(); // Implement login check logic

    if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
        next({path: '/signin', query: {redirect: to.fullPath}}); // Pass redirect URL
    } else {
        next(); // Allow navigation if authenticated or route doesn't require auth
    }
});

export default router;
