<script setup>
import {usePayement} from "@/services-apis/payements/hooks.payements";

const {payement} = usePayement()
</script>
<template>
  <div class="card mt-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Informations sur l'interaction</h6>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <div class="row">

        <!--
                <div class="col-md-12 mb-md-0 mb-4">
                  <div
                      class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
                  >
                    <img
                        class="w-10 me-3 mb-0"
                        src="@/assets/img/logos/mastercard.png"
                        alt="logo"
                    />
                    <h6 class="mb-0">
                      ****&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;7852
                    </h6>
                    <i
                        class="fas fa-pencil-alt ms-auto text-dark cursor-pointer"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title
                        aria-hidden="true"
                        data-bs-original-title="Edit Card"
                        aria-label="Edit Card"
                    ></i>
                  </div>
                </div>
        -->

      </div>

      <div class="row mt-3">

        <div class="col-md-6 mb-md-0 mb-4">
          <div
              class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
          >
            <h6 class="mb-0">
              Client
            </h6>

            <span class="px-2 text-truncate" style="width: 12em">
            {{ payement?.user?.firstname || payement?.user?.email }} {{ payement?.user?.lastname }}
            </span>
            <i
                class="fas fa-user ms-auto text-dark cursor-pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title
                aria-hidden="true"
                data-bs-original-title="Edit Card"
                aria-label="Edit Card"
            ></i>
          </div>
        </div>

        <!--        Une carte de paiement-->
        <div class="col-md-6">
          <div
              class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
          >

            <h6 class="mb-0">
              Agent
            </h6>

            <span class="px-2 text-truncate" style="width: 12em">
              {{ payement?.agent?.firstname || payement?.agent?.email }} {{ payement?.agent?.lastname }}
            </span>

            <i
                class="fas fa-user ms-auto text-dark cursor-pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title
                aria-hidden="true"
                data-bs-original-title="Edit Card"
                aria-label="Edit Card"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
