<script setup>
import {commandeStore} from "@/store/pinia-stores/commande-store";

const store = commandeStore()
import dayjs from "dayjs";
</script>

<template>
  <div class="modal fade" id="detailArticleModal" data-bs-keyboard="false" tabindex="1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen-sm-down">
      <div class="modal-content">
        <div class="modal-body pt-1">
          <div class="card-header pb-0 px-3">
            <h6 class="mb-0 modal-title">Informations sur l'article</h6>
          </div>
          <div class="card-body pt-4 p-3">
            <ul class="list-group">

              <!--        Details textuel de la commande-->
              <li
                  class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
              >
                <div class="d-flex flex-column">
                  <h6 class="mb-3 text-sm">Détails de l'article</h6>
                  <span class="mb-2 text-sm">
              Nom:
              <span class="text-dark font-weight-bold ms-sm-1 text-sm">{{ store?.currentArticle?.name }}</span>
            </span>

                  <div class="grid grid-cols-1 gap-2">
                             <span class="mb-2 text-sm">
              Description:
              <span class="text-dark ms-sm-1 text-xs font-weight-bold">
                {{ store?.currentArticle?.description || 'Aucune description fournie par le client' }}
              </span>
                             </span>
                  </div>

                  <p class="mb-2 text-sm">
                    Prix:
                    <span class="ms-sm-1 text-sm text-success font-weight-bold"
                    >{{ store?.currentArticle?.price || 'Pas encore défini' }}</span
                    >
                  </p>
                  <span class="text-sm">
              Ajouté:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">{{
                  dayjs(store?.currentArticle?.createdAt).locale('fr').format('DD MMM YYYY')
                }}</span>
            </span>
                </div>
              </li>

              <li
                  class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
              >
                <div class="d-flex flex-column">
                  <h6 class="mb-3 text-sm">Images</h6>
                  <div class="d-flex flex-row flex-wrap w-100">
                    <img src="https://i.pinimg.com/564x/5b/ab/33/5bab33aeb4f7382e6c82d94b784fcb33.jpg"
                         class="img-thumbnail" alt="Image de l'article">
                    <img src="https://i.pinimg.com/564x/41/27/12/412712225c236be7e0d8633cbf32f5c4.jpg"
                         class="img-thumbnail" alt="Image de l'article">
                    <img src="https://i.pinimg.com/564x/bc/63/50/bc6350c083d49e7dfb322b93c555896f.jpg"
                         class="img-thumbnail" alt="Image de l'article">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>


        <div class="w-100 d-flex align-items-center justify-content-center mb-4">
          <button type="button" class="btn btn-sm btn-dark float-right mb-0 d-lg-block w-75"
                  data-bs-dismiss="modal">Terminer
          </button>
        </div>
        </div>

      </div>
    </div>
</template>
