<script setup>
import router from "@/router";
import {payementStore} from "@/store/pinia-stores/payements-store";

defineProps(['payement'])

const store = payementStore();

// Aller à un payement
const getToOne = (id) => {
  store.setCurrentId(id)
  router.push({name: 'DetailsPayement'})
}

</script>

<template>
  <!-- Example split danger button -->
  <div class="btn-group">
    <button type="button" class="btn btn-link text-success text-xs pb-0" @click="getToOne(payement?.id)">
      Détails
    </button>

    <button type="button" @click="store.setCurrentId(payement?.id)"
            class="btn btn-link text-dark font-weight-normal text-xs pb-0"
            data-bs-toggle="dropdown"
            aria-expanded="false">
      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
    </button>
    <ul class="dropdown-menu shadow-card">
      <li>
        <button type="button" @click="getToOne(payement?.id)" class="dropdown-item text-xs">
          <i class="fas fa-plus-circle"></i>
          Voir détails
        </button>
      </li>
      <li v-if="payement?.isWaiting">
        <button
            type="button" class="dropdown-item   text-xs"
            data-bs-toggle="modal" data-bs-target="#takeStepCommande"
        >
          <i class="fas fa-check"></i>
          Lancer les démarches
        </button>
      </li>

      <li>
        <hr class="dropdown-divider">
      </li>
      <li><a class="dropdown-item" href="#">Autres actions</a></li>
    </ul>

  </div>

</template>


