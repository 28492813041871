<script setup>
import {useMyPayements, usePayement} from "@/services-apis/payements/hooks.payements";
import dayjs from "dayjs";
import {payementStore} from "@/store/pinia-stores/payements-store";
import router from "@/router";
import {editNotificationState} from "@/services-apis/payements";

const relativeTime = require('dayjs/plugin/relativeTime')
const locale = require('dayjs/locale/fr')
dayjs.locale(locale)
dayjs.extend(relativeTime)


const {refetch, payements} = useMyPayements()
const {refetch: refretchOne} = usePayement()

const store_payement = payementStore()

/*En cliquant sur un paiement, on doit voir les détails*/
const getToPayement = async (paym) => {
  store_payement.setCurrentId(paym.id)
  await refetch()
  await router.push({name: 'DetailsPayement'})
}


// Recharger les donner
const refetchData = async () => {
  await refetch()
  await refretchOne()
}

// Désactiver un paiement
const desactiveNotification = async (paym) => {
  editNotificationState({
    ids: [paym?.id,],
    value: !paym?.notificationEnabled
  }, refetchData, paym?.notificationEnabled ? 'Notifications désactivées' : 'Notifications activées')
}
</script>

<template>
  <ul
      class="px-2 py-3 dropdown-menu dropdown-menu-end mt-0"
      style="max-height: 80vh; overflow-y: auto;"
      :class="showMenu ? 'show' : ''"
      aria-labelledby="dropdownMenuButton"
  >

    <li v-if="payements?.length === 0" class="d-flex justify-content-center align-items-center flex-column">
      <img src="https://i.pinimg.com/564x/f8/99/78/f8997879fe9228ecf1b6e1b47ccb6370.jpg" alt="empty illustration" class="img img-fluid"  />
      <span class="text-gray my-3 text-sm">Aucun paiement</span>
    </li>

    <li v-for="payement in payements" :key="payement?.id" class="mb-2">
      <div class="dropdown-item border-radius-md mw-100" @click="getToPayement(payement)">
        <div class="py-1 d-flex">
          <div class="my-auto">
            <i class="far fa-bell p-2 border-radius-md bg-success text-white mx-2"></i>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <h6 class="mb-1 text-sm font-weight-normal text-truncate" style="width: 300px;">
              <span class="font-weight-bold">Nouveau paiement</span> de <span
                class="text-decoration-underline font-weight-bold">{{
                payement?.user?.firstname || payement?.user?.email
              }} {{ payement?.user?.lastname }}Nouveau paiement</span> de <span
                class="text-decoration-underline font-weight-bold">{{
                payement?.user?.firstname || payement?.user?.email
              }} {{ payement?.user?.lastname }}</span>
            </h6>
            <div class="d-flex flex-row">
              <span class="mb-0 text-xs text-secondary text-truncate px-1" style="max-width: 120px">
                <i class="fa fa-clock me-1"></i>
                {{ dayjs(payement?.payedAt).fromNow() }}
              </span>

              <span class="mb-0 text-xs text-secondary text-truncate px-1" style="max-width: 120px">
                <i class="fas fa-file-invoice"></i>
                {{ payement?.totalPayed }} {{ payement?.devise?.symbole }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <button @click="desactiveNotification(payement)" type="button"
              :class="{'text-danger':payement?.notificationEnabled, 'text-dark': !payement?.notificationEnabled}"
              class="btn btn-link text-xs m-0">
        {{ payement?.notificationEnabled ? 'Désactiver' : 'Activer' }} la notification du paiement
      </button>

      <hr class="my-1 horizontal dark"/>
    </li>
    <!--====================================================================-->

  </ul>
</template>

<style scoped>

</style>
