<script setup>
import {computed, ref} from "vue";
import {setArticlePrice} from "@/services-apis/articles/article-request";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import {commandeStore} from "@/store/pinia-stores/commande-store";

const props = defineProps(['article'])
const prevPrice = computed(() => props.article.price)
const price = ref('')
const {commande, refetch} = useCommande()
const store = commandeStore()

// Envoyer le nouveau price
const handleChangePrice = () => {
  if (prevPrice.value !== price.value) {
    if (!price.value) {
      return
    }

    // Changer maintenant
    setArticlePrice(props.article.id,
        {commandId: Number(store.currentId), price: price.value, deviseId: 1},
        async () => {
          await refetch()
        },
        () => {
          price.value = ''
        }
    )
  } else {
    // console.log("Prix identique")
  }
}

</script>

<template>
  <div class="d-flex align-items-center justify-content-between w-100">
    <div class="d-flex align-items-center w-50">
      <div class="avatar-sm">
        <img src="https://i.pinimg.com/564x/5b/ab/33/5bab33aeb4f7382e6c82d94b784fcb33.jpg" alt="Avatar"
             class="img-circle"/>
      </div>

      <div class="d-flex flex-column" data-bs-toggle="modal" data-bs-target="#detailArticleModal"
           @click="store.setCurrentArticle(article)">
        <h6 class="mb-1 text-dark text-sm text-start px-1" style="max-height: 6rem; overflow: hidden">
          {{ article?.name }}</h6>
        <span class="text-xs text-truncate" style="max-width: 250px; overflow-x: scroll;scrollbar-width: none;">
          {{ article?.description || '- Aucune description -' }}
        </span>
      </div>

    </div>

    <div v-if="article?.price" class="d-flex flex-row align-items-center font-weight-bold">
      <input
          :readonly="commande?.isCompletePayed"
          class="form-control btn-xs text-success input-group-outline text-end border-0 btn-outline-success font-weight-bold mx-2"
          :value="article?.price" placeholder="price dans le champs" style="max-width: 90px; min-width: 90px"/>
      $
    </div>

    <!--          Si le price n'est pas connu-->
    <div v-if="!article?.price" class="d-flex flex-row align-items-center font-weight-bold">
      <input
          v-model="price" @blur.stop="handleChangePrice"
          class="form-control btn-xs text-success input-group-outline text-end border-0 btn-outline-success font-weight-bold mx-2"
          placeholder="Prix dans le champs" style="max-width: 105px"/>
      $
    </div>
  </div>

</template>
