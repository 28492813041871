<script setup>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import Breadcrumbs from "../Breadcrumbs.vue";
import {userStore} from "@/store/pinia-stores/user.store";
import Notifications from "@/views/components/Notifications.vue";

const showMenu = ref(false);
const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const route = useRoute();

const currentRouteName = computed(() => {
  return route.name;
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});

const minimizeSidebar = () => store.commit("sidebarMinimize");
// const toggleConfigurator = () => store.commit("toggleConfigurator");

const closeMenu = () => {
  setTimeout(() => {
    showMenu.value = false;
  }, 100);
};

// Utilisateur courant
const storeUser = userStore()
const user = computed(() => storeUser?.user)

</script>
<template>
  <nav
      class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-md"
      :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
      v-bind="$attrs"
      id="navbarBlur"
      data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
          :current-page="currentRouteName"
          :current-directory="currentDirectory"
      />

      <div
          class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
          :class="isRTL ? 'px-0' : 'me-sm-4'"
          id="navbar"
      >
        <div
            class="pe-md-3 d-flex align-items-center"
            :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
<!--          <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
                type="text"
                class="form-control"
                :placeholder="isRTL ? 'أكتب هنا...' : 'Rechercher ici...'"
            />
          </div>-->
        </div>

        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center mx-2">
            <router-link
                :to="{ name: user ? 'Profile':'Signin' }"
                class="px-0 nav-link font-weight-bold text-white"
                :target=" user ?'': '_blank'"
            >
              <!--              <span v-if="isRTL" class="d-sm-inline d-none">يسجل دخول</span>-->
              <span v-if="user"
                    class="d-sm-inline d-none">{{ user?.firstname || 'Utilisateur' }} {{ user?.lastname }}</span>
              <span v-else class="d-sm-inline d-none">Connexion</span>
            </router-link>
          </li>

          <li class="nav-item d-xl-none ps-3 d-flex align-items-center mx-2">
            <a
                href="#"
                @click="minimizeSidebar"
                class="p-0 nav-link text-white"
                id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>

<!--          <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link text-white" @click="toggleConfigurator">
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>-->


          <li
              class="nav-item dropdown d-flex align-items-center"
              :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <button
                class="nav-link text-white btn mb-0 py-2 px-3"
                :class="[showMenu ? 'show' : '']"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="showMenu = !showMenu"
                @blur="closeMenu"
            >
              <i class="cursor-pointer fa fa-bell text-white"></i>
            </button>

            <!--            Les notifications de l'utilisateur en cours-->
            <notifications/>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
