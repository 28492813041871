<script setup>
import {confirmPayement} from "@/services-apis/payements";

import {payementStore} from "@/store/pinia-stores/payements-store";
import {usePayement, usePayements} from "@/services-apis/payements/hooks.payements";

const {refetch}= usePayements()
const {refetch: refretchOne}= usePayement()
const store = payementStore()
const props = defineProps(['payement'])

const refetchData = async ()=>{
  await refetch()
  await refretchOne()
}

const confirmNow = () => {
  confirmPayement({ids: [props.payement?.id,] }, refetchData, 'Paiement confirmé')
}

</script>

<template>
  <div class="dropdown">

    <button type="button" class="btn btn-link text-dark px-3 mb-0 dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
      <i class="fas fa-check text-dark me-2" aria-hidden="true"></i>
      {{ store.isLoading ? 'En cours ... ' : 'Confirmer' }}
    </button>

    <ul class="dropdown-menu px-2">
      <li>
        <button @click="confirmNow" type="button"
                class="dropdown-item btn btn-link text-dark text-sm px-3 font-weight-normal mb-0">
          Oui
        </button>
      </li>
      <li>
        <button type="button" class="dropdown-item btn btn-link text-danger text-sm px-3 font-weight-normal mb-0">Non
        </button>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>
