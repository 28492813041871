import api from "../axios-init";
import {useQuery} from "@tanstack/vue-query";
import {computed} from "vue";
import {userStore} from "@/store/pinia-stores/user.store";
import {payementStore} from "@/store/pinia-stores/payements-store";

// Utiliser toutes les factures / paiements
export const usePayements = () => {

    const getPayements = async () => {
        let token = userStore()?.tokens?.accessToken
        let searchValue = payementStore()?.textSearch

        let page = searchValue ? undefined : payementStore()?.page
        let items = searchValue ? undefined : payementStore()?.items || 5

        let params = {page, items}
        let headers = {Authorization: `Bearer ${token}`}
        const res = await api.get('/admin/payements/', {headers, params})
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isLoading, isError,
        error
    } = useQuery({
        queryKey: ['get-all-payements'],
        queryFn: getPayements,
        refetchInterval: 3000
    })

    const payements = computed(() => response.value?.payements)
    const totalItems = computed(() => response.value?.totalItems)

    return {
        isPending, isLoading, refetch, totalItems, isError, payements: payements, error
    }
}


// Utiliser tous les paiements qui concernent un seul admin
export const useMyPayements = () => {

    const getMyPayements = async () => {
        let token = userStore()?.tokens?.accessToken
        let searchValue = payementStore()?.textSearch

        let page = searchValue ? undefined : payementStore()?.page
        let items = searchValue ? undefined : payementStore()?.items || 5

        let params = {page, items}
        let headers = {Authorization: `Bearer ${token}`}
        const res = await api.get('/admin/my-payements/', {headers, params})
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isFetching, isError,
        error
    } = useQuery({
        queryKey: ['get-my-payements'],
        queryFn: getMyPayements,
        refetchInterval: 3000
    })

    const payements = computed(() => response.value?.payements)
    const totalItems = computed(() => response.value?.totalItems)

    return {
        isPending, isFetching, refetch, totalItems, isError, payements: payements, error
    }
}


// Utiliser un paiement
export const usePayement = () => {

    const getOnePayement = async () => {
        let token = userStore()?.tokens?.accessToken
        let id = payementStore().currentId
        let headers = {Authorization: `Bearer ${token}`}
        const res = id ? await api.get(`/admin/payement/${id}`, {headers}) : null
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isLoading, isError,
        error
    } = useQuery({
        queryKey: ['get-one-payement'],
        queryFn: getOnePayement,
        staleTime: Infinity,
        refetchInterval: 2500
    })

    const payement = computed(() => response.value?.payement)
    const commande = computed(() => response.value?.command)
    const articles = computed(() => commande.value?.articles)
    const payement_articles = computed(() => response.value?.articles_payements)

    return {
        isPending,
        isLoading,
        refetch,
        commande,
        articles,
        payement_articles,
        isError,
        payement: payement,
        error
    }
}
