<script setup>
import {editNotificationState} from "@/services-apis/payements";

import {payementStore} from "@/store/pinia-stores/payements-store";
import {usePayement, usePayements} from "@/services-apis/payements/hooks.payements";

const {refetch} = usePayements()
const {refetch: refretchOne} = usePayement()
const store = payementStore()
const props = defineProps(['payement'])

// Recharger les donner
const refetchData = async () => {
  await refetch()
  await refretchOne()
}

const desactiveNotification = () => {
  editNotificationState({
    ids: [props.payement?.id,],
    value: !props.payement?.notificationEnabled
  }, refetchData, props.payement?.notificationEnabled ? 'Notifications désactivées' : 'Notifications activées')
}

</script>

<template>
  <div class="dropdown">
    <button type="button"
            :class="{'text-danger':payement?.notificationEnabled, 'text-dark': !payement?.notificationEnabled}"
            class="btn btn-link dropdown-toggle px-3 mb-0" data-bs-toggle="dropdown"
            aria-expanded="false">
      <i class="fas fa-bell me-2"
         :class="{'text-danger':payement?.notificationEnabled, 'text-dark': !payement?.notificationEnabled}"
         aria-hidden="true"></i>
      {{ store.isLoading ? 'En cours ... ' : payement?.notificationEnabled ? 'Désactiver' : 'Activer' }}
    </button>

    <ul class="dropdown-menu px-2">
      <li>
        <button @click="desactiveNotification" type="button"
                :class="{'text-danger':payement?.notificationEnabled, 'text-dark': !payement?.notificationEnabled}"
                class="dropdown-item btn btn-link text-sm px-3 font-weight-normal mb-0">
          {{ payement?.notificationEnabled ? 'Oui, désactiver' : 'Oui, activer' }}
        </button>
      </li>
      <li>
        <button type="button" class="dropdown-item btn btn-link text-dark text-sm px-3 font-weight-normal mb-0">Non
        </button>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>
