// Récupérer les commandes
import {useQuery} from "@tanstack/vue-query";
import api from "@/services-apis/axios-init";
import {userStore} from "@/store/pinia-stores/user.store";
import {commandeStore} from "@/store/pinia-stores/commande-store";

// Utiliser toutes les commandes
export const useCommandes = () => {

    // Toutes les commandes
    const getCommands = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get('/admin/commands/', {headers})
        return res?.data?.data || []
    }

    const {
        data: commandes, isPending, isLoading, isError, error
    } = useQuery({
        queryKey: ['get-all-commands'], queryFn: getCommands, refetchInterval: 3000
    })

    // console.log({commandes})

    return {
        isPending, isLoading, isError, commandes, error
    }
}


// Utiliser toutes les commandes
export const useCommande = () => {

    // Toutes les commandes
    const getOneCommande = async () => {
        let id = commandeStore()?.currentId
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get(`/admin/command/${id}`, {headers})
        return res?.data?.data
    }

    const {
        data: commande, isPending, isLoading, isError, error, refetch
    } = useQuery({
        queryKey: ['get-one-commande'], queryFn: getOneCommande, refetchInterval: 3000
    })

    return {
        isPending, isLoading, isError, commande, error, refetch
    }
}

// Utiliser les raisons pour lesquelles une commande est acceptée / révoquée
export const useRevokeReasons = () => {

    // Toutes les raisons "revoke" commandes
    const getRevokeReasons = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        let params = {type: 'revoke'}
        // console.log({token})
        const res = await api.get(`/admin/accept-revoke-reason/`, {headers, params})
        return res?.data?.data
    }

    const {
        data: reasons, isPending, isFetching, isError, error, refetch
    } = useQuery({
        queryKey: ['get-all-revoke-reasons'], queryFn: getRevokeReasons, refetchInterval: Infinity
    })

    return {
        isPending, isFetching, isError, reasons, error, refetch
    }
}



// Utiliser les raisons pour lesquelles une commande est acceptée / révoquée
export const useAcceptReasons = () => {

    // Toutes les raisons "accept" commandes
    const getReasons = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        let params = {type: 'accept'}
        // console.log({token})
        const res = await api.get(`/admin/accept-revoke-reason/`, {headers, params})
        return res?.data?.data
    }

    const {
        data: reasons, isPending, isFetching, isError, error, refetch
    } = useQuery({
        queryKey: ['get-all-accept-reasons'], queryFn: getReasons, refetchInterval: Infinity
    })

    return {
        isPending, isFetching, isError, reasons, error, refetch
    }
}
